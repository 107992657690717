import Image from 'next/legacy/image'

// Components
import Navigation from '@/components/Navigation'

import Suitcase from '@/assets/img/valise.png'

const gradient = {
  background:
    'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(205.67deg, #F8EAE9 6.98%, #D7C9DA 64.52%, #66B4D9 123.27%)',
}

function AuthLayout({ children, title }) {
  return (
    <div className="bg-white min-h-screen">
      <Navigation />
      <div className="pt-180 flex flex-col min-h-screen">
        <main className="h-full flex-grow">
          <div className="container">
            <div className="row justify-center">
              <div className="md:col-8 lg:col-6">
                <h1 className="text-30 md:text-40 font-bold leading-none mb-15">
                  {title}
                </h1>
                {children}
              </div>
            </div>
          </div>
        </main>
        <div className="flex justify-center" style={gradient}>
          <div className="h-300 relative w-full">
            <Image src={Suitcase} alt="" layout="fill" objectFit="contain" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
