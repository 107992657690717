import { useRouter } from 'next/router'
import useTranslation from '@/hooks/translation'

import AuthLayout from '@/layouts/auth'
import Head from '@/components/Head'
import LoginComponent from '@/components/auth/Login'
import GoogleAuth from '@/components/auth/GoogleAuth'
import { getServerSideUserProps } from '@/utils/auth'
import Button from '@/components/ui/button'

export default function Login() {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <>
      <Head
        title={t('pages.login.seoTitle')}
        description={t('pages.login.seoDescription')}
      />
      <p className="text-dark-60 mb-25">{t('pages.login.subtitle')}</p>
      <div className="mb-30">
        <GoogleAuth
          buttonColor="dark"
          onSuccess={() => {
            if (router.query.next_url) {
              router.push(router.query.next_url)
            } else {
              router.push('/')
            }
          }}
        />
      </div>
      <LoginComponent
        onSuccess={() => {
          if (router.query.next_url) {
            router.push(router.query.next_url)
          } else {
            router.push('/')
          }
        }}
      />
      <div className="flex flex-wrap items-baseline mt-20">
        <span className="mr-5">{t('components.navigation.noAccount')}</span>
        <Button plain color="primary" href="/register">
          {t('components.navigation.createAccount')}
        </Button>
      </div>
      <p className="text-14 text-dark-80 mt-30 mb-30">
        {t('pages.login.feedback')}{' '}
        <a
          href="mailto:support@ulysse.com"
          className="font-bold underline hover:no-underline"
        >
          support@ulysse.com
        </a>
      </p>
    </>
  )
}

Login.getLayout = function LoginLayoutWrapper({ children }) {
  const { t } = useTranslation()
  return <AuthLayout title={t('pages.login.title')}>{children}</AuthLayout>
}

export const getServerSideProps = async (context) => {
  const {
    props: { user },
  } = await getServerSideUserProps(context)

  if (user && context.resolvedUrl.startsWith('/login')) {
    return {
      redirect: {
        destination: context?.query.next_url ?? '/',
        permanent: false,
      },
    }
  }

  return { props: {} }
}
